import { MetatagItem } from 'models';
import Head from 'next/head';
import React, { FC } from 'react';

export const MainMetaHead: FC<{
  items: MetatagItem[];
}> = ({ items }): JSX.Element => {
  return (
    <Head>
      {items?.map((elem) => (
        <meta name={elem?.elements?.name?.value} content={elem?.elements?.content?.value} />
      ))}
    </Head>
  );
};

export default MainMetaHead;
